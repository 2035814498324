import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import MetaTags from 'components/MetaTags';
import { ConfirmationDialogProvider } from 'contexts/confirmation';
import { CurrentUserProvider } from 'contexts/user'; // Moved this import down
import 'react-toastify/dist/ReactToastify.css';
import apolloClient from 'services/graphql/client';
import { launchDarklyConfig } from 'services/launchDarkly/launchDarkly.config';
import { initializeSentry } from 'services/sentry/sentry';
import Box from 'yoda-ui/components/Box';
import { yodaTheme, YodaColors } from 'yoda-ui/yodaTheme';

// Setup Sentry
initializeSentry();

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <ApolloProvider client={ apolloClient }>
        <CurrentUserProvider>
          <ThemeProvider theme={ yodaTheme }>
            <StyledEngineProvider injectFirst>
              <ErrorBoundary>
                <ConfirmationDialogProvider>
                  <Box
                    display="flex"
                    flexDirection="column"
                    color={ YodaColors.background }
                    height="100vh"
                  >
                    <ToastContainer theme='colored' />

                    <Layout>
                      <Routes />
                    </Layout>

                  </Box>
                </ConfirmationDialogProvider>
              </ErrorBoundary>
            </StyledEngineProvider>
          </ThemeProvider>
        </CurrentUserProvider>
      </ApolloProvider>
      <MetaTags />
    </BrowserRouter>
  </HelmetProvider>
);

export default withLDProvider(launchDarklyConfig)(App);
