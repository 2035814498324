import { t } from 'i18next';
import { FC } from 'react';
import useTacticCreate from './TacticForm.hook';
import { Channel } from 'basics/enums/maya.enums';
import ActionButton from 'components/ActionButton';
import ContentCard from 'components/ContentCard';
import { formContainerActionsStyle } from 'components/FormContainer/FormContainer.style';
import { FormLayoutContainer } from 'components/FormLayout';
import InfoTag from 'components/InfoTag/InfoTag';
import { TacticFormPropsTypes } from 'components/TacticForm/TacticForm.types';
import { ApprovedEmail } from 'components/TacticForm/TacticsChannel/ApprovedEmail';
import { BroadcastEmail } from 'components/TacticForm/TacticsChannel/BroadcastEmail';
import { F2fEvent } from 'components/TacticForm/TacticsChannel/F2fEvent';
import { F2fMeeting } from 'components/TacticForm/TacticsChannel/F2fMeeting';
import { HybridEvent } from 'components/TacticForm/TacticsChannel/HybridEvent';
import { OneToOne } from 'components/TacticForm/TacticsChannel/OneToOne';
import { Telephone } from 'components/TacticForm/TacticsChannel/Telephone';
import { VirtualEvent } from 'components/TacticForm/TacticsChannel/VirtualEvent';
import { VirtualMeeting } from 'components/TacticForm/TacticsChannel/VirtualMeeting';
import { WebLogIn } from 'components/TacticForm/TacticsChannel/WebLogIn';
import { WebNoLogIn } from 'components/TacticForm/TacticsChannel/WebNoLogIn';
import Box from 'yoda-ui/components/Box';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import Loader from 'yoda-ui/components/Loader';
import { YodaFormProvider } from 'yoda-ui/yodaForm';
import { fromUnitsToRem, YodaJustifyContent, YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticForm: FC<TacticFormPropsTypes> = ({ isTemplate }) => {
  const {
    cancelButtonConfig,
    keyMessagesLoading,
    providerFields,
    selectedChannel,
    submitButtonConfig,
    tacticFormConfig,
    channelEngagement,
    channelEngagementLoading,
    requestAssetButtonConfig,
  } = useTacticCreate(isTemplate);

  const FormDisplayByChannel = (channel: Channel) => {
    switch (channel) {
      case Channel.approvedEmail:
        return (
          < ApprovedEmail tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.broadcastEmail:
        return (
          < BroadcastEmail tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.f2fEvent:
        return (
          < F2fEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.f2fMeeting:
        return (
          < F2fMeeting tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.hybridEvent:
        return (
          < HybridEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.oneToOne:
        return (
          < OneToOne tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.telephone:
        return (
          < Telephone tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.virtualEvent:
        return (
          < VirtualEvent tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.virtualMeeting:
        return (
          < VirtualMeeting tacticFormConfig={ tacticFormConfig } keyMessagesLoading={ keyMessagesLoading } />
        );
      case Channel.webLogIn:
        return (
          < WebLogIn tacticFormConfig={ tacticFormConfig } />
        );
      case Channel.webNoLogIn:
        return (
          < WebNoLogIn tacticFormConfig={ tacticFormConfig } />
        );
      default:
        return null;
    }
  };

  return (
    <YodaFormProvider { ...providerFields }>

      <ContentCard sx={ { margin: YodaSpacing.medium } }>
        <FormLayoutContainer justifyContent={ YodaJustifyContent.spaceBetween } >
          <FormLayoutContainer
            flexDirection="column"
            justifyContent={ YodaJustifyContent.spaceBetween }
          >

            <Box marginBottom={ YodaSpacing.large }>
              <SelectYoda { ...tacticFormConfig.channel } overflow={ 'auto' } maxHeight={ 200 }/>
            </Box>

            { FormDisplayByChannel(selectedChannel as Channel) }

          </FormLayoutContainer>
        </FormLayoutContainer>
      </ContentCard>
      {
        selectedChannel && (<ContentCard sx={
          {
            marginBottom: fromUnitsToRem(YodaSpacing.large),
            paddingY: fromUnitsToRem(YodaSpacing.small),
            marginX: fromUnitsToRem(YodaSpacing.medium),
          }
        }>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box marginBottom={ YodaSpacing.xxSmall } marginTop={ YodaSpacing.xxSmall }>
              {
                channelEngagementLoading
                  ? <Box display="flex" alignItems="center" marginLeft={ YodaSpacing.xxSmall }>
                    <Loader size={ 1.25 } center />
                  </Box>
                  : <Box>
                    <InfoTag
                      title={ t('maya_tactic_engagement') }
                      field={ channelEngagement?.engagement.toString() }
                    />
                    <InfoTag
                      title={ t('maya_tactic_engagement_percentage') }
                      field={ `${channelEngagement?.percentage.toString()}%` }
                    />
                  </Box>
              }

            </Box>
          </Box>
        </ContentCard>)
      }

      <Box sx={ formContainerActionsStyle } className='tacticFormButtonsWrapper'>
        { cancelButtonConfig ? <ActionButton { ...cancelButtonConfig } /> : <Box></Box> }
        { requestAssetButtonConfig && <ActionButton { ...requestAssetButtonConfig } /> }
        { submitButtonConfig && <ActionButton { ...submitButtonConfig } /> }
      </Box>
    </YodaFormProvider>
  );
};

export default TacticForm;

