import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VeevaSyncStatus } from 'basics/enums/maya.enums';
import ButtonsBar from 'components/ButtonsBar';
import {
  FormLayoutContainer,
  FormLayoutMidPanel,
  FormLayoutMidPanelContent,
  FormLayoutMidPanelHeader,
} from 'components/FormLayout';
import Heading from 'components/Heading';
import MayaLayout from 'components/MayaLayout/MayaLayout';
import VeevaStatusBar from 'components/VeevaStatusBar/VeevaStatusBar';
import useCepView from 'routes/AuthenticatedApp/CepView/CepView.hook';
import { CepViewStepsIndex } from 'routes/AuthenticatedApp/CepView/CepView.types';
import CepConfigView from 'routes/AuthenticatedApp/CepView/Steps/CepConfigView/CepConfigView';
import CepGoalView from 'routes/AuthenticatedApp/CepView/Steps/CepGoalView/CepGoalView';
import TacticsView from 'routes/AuthenticatedApp/CepView/Steps/TacticsView/TacticsView';
import TargetListView from 'routes/AuthenticatedApp/CepView/Steps/TargetListView/TargetListView';
import Box from 'yoda-ui/components/Box';
import Stepper from 'yoda-ui/components/Stepper/Stepper';

const CepView: FC = () => {
  const { t } = useTranslation();
  const {
    buttonsConfig,
    cep,
    handleChangeStep,
    stepIndexer,
    stepsList,
  } = useCepView();

  return (
    <MayaLayout>
      <FormLayoutContainer>
        <FormLayoutMidPanel >
          <FormLayoutMidPanelHeader>
            <Stepper
              handleChangeStep={ handleChangeStep }
              stepsList={ stepsList }
              stepIndex={ stepIndexer.stepIndex }
            />
          </FormLayoutMidPanelHeader>
          <FormLayoutMidPanelContent>
            <Heading>
              { t('maya_cep_view') }
              <VeevaStatusBar
                lastVeevaSync={ cep?.lastVeevaSync }
                lastVeevaSyncErrors={ cep?.lastVeevaSyncError ? [cep?.lastVeevaSyncError] : undefined }
                veevaSyncStatus={ cep?.veevaSyncStatus as VeevaSyncStatus }
              />
            </Heading>

            {
              stepIndexer.stepIndex === CepViewStepsIndex.configurationStep
                ? (
                  <Box width="100%">
                    <CepConfigView cep={ cep } />
                  </Box>
                )
                : null
            }
            {
              stepIndexer.stepIndex === CepViewStepsIndex.goalStep
                ? (
                  <Box width="100%">
                    <CepGoalView cep={ cep } />
                  </Box>
                )
                : null
            }
            {
              stepIndexer.stepIndex === CepViewStepsIndex.targetListStep
                ? (
                  <Box width="100%">
                    <TargetListView/>
                  </Box>
                )
                : null
            }
            {
              stepIndexer.stepIndex === CepViewStepsIndex.tacticsStep
                ? (
                  <Box width="100%">
                    <TacticsView/>
                  </Box>
                )
                : null
            }
            { buttonsConfig && <ButtonsBar { ...buttonsConfig } /> }
          </FormLayoutMidPanelContent>
        </FormLayoutMidPanel>

      </FormLayoutContainer>
    </MayaLayout>
  );
};

export default CepView;

