import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CepSteps } from 'basics/enums/maya.enums';
import { CepStateType } from 'basics/types/maya.types';
import { CepFormStepsIndex } from 'components/CepForm/CepForm.types';
import cepState from 'states/cep.states';
import { StepItem } from 'yoda-ui/components/Stepper/Stepper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCepFormStepper = (useWatchStep: any) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const watchConfigurationStep = useWatchStep(CepSteps.configurationStep);
  const watchGoalStep = useWatchStep(CepSteps.goalStep);

  const customerEngagementPlanningStateValue = useSnapshot(cepState) as CepStateType;

  const disableStep = !customerEngagementPlanningStateValue.cep?._id;

  const stepsList: StepItem[] = [
    {
      title: `1. ${t('maya_cep_step_configuration')}`,
      isValid: watchConfigurationStep?.isValid || false,
      disabled: disableStep,
    },
    {
      title: `2. ${t('maya_cep_step_target_list')}`,
      isValid: customerEngagementPlanningStateValue.targetList.length || false,
      disabled: disableStep,
    },
    {
      title: `3. ${t('maya_cep_step_tactics')}`,
      isValid: customerEngagementPlanningStateValue.tactics.length || false,
      disabled: disableStep,
    },
    {
      title: `4. ${t('maya_cep_step_goal')}`,
      isValid: watchGoalStep?.isValid || false,
      disabled: disableStep,
    },
  ];

  if (!flags.mayaBiogenlinc4793EnableGoalStep) {
    stepsList.splice(CepFormStepsIndex.goalStep, 1);
  }

  return stepsList;
};

export default useCepFormStepper;
