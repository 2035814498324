import AuthenticatedApp from './AuthenticatedApp';
import Login from './Login';
import { useCurrentUserState } from 'contexts/user';
import Box from 'yoda-ui/components/Box';
import Loader from 'yoda-ui/components/Loader';

const Routes = () => {
  const { userLoading, user } = useCurrentUserState();

  if (userLoading) {
    return <Box display="flex" flexDirection="column" justifyContent="center" width="100vw"><Loader center /></Box>;
  }

  return user ? <AuthenticatedApp /> : <Login />;
};

export default Routes;
