import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import useTacticFormConfig from './TacticForm.config';
import { KEY_MESSAGES_STATUS, PAGINATION_PAGE_SIZE } from 'basics/constants/common.constant';
import { BiogenProduct } from 'basics/enums/biogen.enums';
import { InitiativeCountry } from 'basics/enums/country.enums';
import { BiogenFunction, Channel, KeyMessageType, TacticMode } from 'basics/enums/maya.enums';
import { useCreateTacticMutation } from 'basics/graphql/mutations/createTactic';
import { useUpdateTacticMutation } from 'basics/graphql/mutations/updateTactic';
import useGetAllVeevaVaultDocumentLazy from 'basics/graphql/queries/getAllVeevaVaultDocumentLazy';
import useGetCepByIdLazyQuery from 'basics/graphql/queries/getCepByIdLazy';
import useGetEngagementLazy from 'basics/graphql/queries/getEngagementLazy';
import useGetKeyMessagesQuery from 'basics/graphql/queries/getKeyMessages';
import useGetNextTacticsQuery from 'basics/graphql/queries/getNextTactics';
import useApprovedDocumentIdOptions from 'basics/options/approvedDocumentId.options';
import useAssetIdOptions from 'basics/options/assetId.options';
import useKeyMessagesOptions from 'basics/options/keyMessages.options';
import { tacticToBE } from 'basics/transformers/Tactic.transformer';
import { KeyMessagesOptionType } from 'basics/types/keyMessages.types';
import { CepStateType, TacticStateType } from 'basics/types/maya.types';
import { succesToast, warningToast } from 'basics/utils/toast';
import { RequestFormUrlMapping, TherapeuticAreaRequestFormMapping } from 'components/TacticForm/TacticForm.constants';
import { CustomerEngagementPlanning, CustomerInitiative, Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const VEEVA_VAULT_DOCUMENT_LIMIT = 10;

const useTacticCreate = (isTemplate: boolean) => {
  const flags = useFlags();
  const { t } = useTranslation();

  const {
    getValues,
    providerFields,
    resetField,
    resetForm,
    setDefaultValue,
    setError,
    setFieldShowError,
    useWatchField,
    useWatchForm,
  } = useYodaCreateForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const tacticStateValue = useSnapshot(tacticState) as TacticStateType;

  const customerInitiative = cepStateValue.cep?.customerInitiative as CustomerInitiative;

  const productValues = Object.values(BiogenProduct);
  const productIndex = productValues.indexOf(cepStateValue.cep?.product as BiogenProduct);
  const product = productValues[productIndex];

  const [selectedVeevaVaultDocumentFilter, setSelectedVeevaVaultDocumentFilter] = useState<string>('');

  const [keyMessagesOptions, setKeyMessagesOptions] = useState<KeyMessagesOptionType[]>([]);

  const keyMessagesFilters = {
    Country: customerInitiative?.country,
    Product: product,
    ActiveStatusFlag: KEY_MESSAGES_STATUS,
  };

  const skip = (!keyMessagesFilters.Country || !keyMessagesFilters.Product);
  const { data: keyMessages, loading: keyMessagesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.keyMessage },
    skip,
  );
  const { data: medicalStrategies, loading: medicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: internationalMedicalStrategies, loading: internationalMedicalStrategiesLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Country: 'INT', Type: KeyMessageType.medicalStrategy },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );
  const { data: discussionTopics, loading: discussionTopicsLoading } = useGetKeyMessagesQuery(
    { ...keyMessagesFilters, Type: KeyMessageType.discussionTopic },
    skip || !flags.mayaBiogenlinc4369EnableFetchAllKeyMessagesTypes,
  );

  const { getAllVeevaVaultDocumentLazy,
    data: allVeevaVaultDocuments,
    loading: allVeevaVaultDocumentsLoading } = useGetAllVeevaVaultDocumentLazy(
    { limit: VEEVA_VAULT_DOCUMENT_LIMIT },
    { name: selectedVeevaVaultDocumentFilter },
  );

  const allKeyMessages = [...keyMessages, ...medicalStrategies, ...internationalMedicalStrategies, ...discussionTopics];

  const currentKeyMessageOption = tacticStateValue.tactic?.keyMessage
    ? [{ ID: tacticStateValue.tactic?.keyMessage?.id, KeyName: tacticStateValue.tactic?.keyMessage?.name }]
    : [];

  const allKeyMessagesOptions = useKeyMessagesOptions(
    !allKeyMessages.length
      ? currentKeyMessageOption
      : allKeyMessages,
  );

  const executingTeam = useWatchField('executingTeam')?.value;

  useEffect(() => {
    if (executingTeam) {
      const filteredKeyMessagesOptions = allKeyMessagesOptions.filter((element) => {
        if (!flags.mayaBiogenlinc5479KeyMessagePerExecutingTeam) {
          return true;
        }

        switch (executingTeam) {
          case BiogenFunction.Commercial:
            return element.commercial;
          case BiogenFunction.Medical:
            return element.medical;
          case BiogenFunction.MarketAccess:
            return element.marketAccess;
          default:
            return false;
        }
      });

      setKeyMessagesOptions(filteredKeyMessagesOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executingTeam]);

  const allApprovedDocumentIdOptions = useApprovedDocumentIdOptions(allVeevaVaultDocuments);
  const allAssetIdOptions = useAssetIdOptions(allVeevaVaultDocuments);

  const onVeevaVaultFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const veevaVaultDocumentKey = event.target.value;
    if (veevaVaultDocumentKey) {
      setSelectedVeevaVaultDocumentFilter(veevaVaultDocumentKey);

      getAllVeevaVaultDocumentLazy({
        variables: {
          limit: {
            limit: VEEVA_VAULT_DOCUMENT_LIMIT,
          },
          filters: {
            name: veevaVaultDocumentKey,
          },
        },
      });
    }
  };
  const debouncedOnVeevaVaultFieldChange = debounce(onVeevaVaultFieldChange, 700);

  const { tacticFormConfig } = useTacticFormConfig(
    tacticStateValue,
    cepStateValue,
    selectedChannel,
    keyMessagesOptions,
    allApprovedDocumentIdOptions,
    allAssetIdOptions,
    debouncedOnVeevaVaultFieldChange,
    allVeevaVaultDocumentsLoading,
  );

  const [createTactic] = useCreateTacticMutation();
  const [updateTactic] = useUpdateTacticMutation();
  const { queryGetCepById } = useGetCepByIdLazyQuery();
  const { getEngagement, data: channelEngagement, loading: channelEngagementLoading } = useGetEngagementLazy();
  const sendToVeeva = !isTemplate;

  const { isValid } = useWatchForm();

  if (tacticState.tactic) {
    const fieldsName = Object.keys(tacticFormConfig);
    setFieldShowError(true, fieldsName);
  }

  const cancelTacticForm = () => {
    resetForm();
    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  };

  const handleSaveTacticForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedTactic = tacticToBE(formValues, tacticStateValue.cepId, keyMessagesOptions);

    const { data: tacticData, error } = await createTactic(formattedTactic, tacticStateValue.cepId, sendToVeeva);

    const { data: { getCustomerEngagementPlanningById: customerEngagementPlanning } } = await queryGetCepById(
      { variables: { id: cepStateValue.cep?._id } },
    );
    if (cepState.cep) {
      cepState.cep.globalCepSyncStatus = (
        customerEngagementPlanning as CustomerEngagementPlanning
      ).globalCepSyncStatus;
    }

    setIsSubmitting(false);
    if (tacticData && !error) {
      succesToast(t('maya_tactic_created', { title: tacticData.title }));
      resetForm();
    } else {
      warningToast(error || t('errors_standard'));
    }

    tacticState.tactic = null;
    tacticState.mode = TacticMode.list;
  };

  const handleUpdateTacticForm = async () => {
    setIsSubmitting(true);
    const formValues = getValues();
    const formattedTactic = tacticToBE(formValues, cepStateValue.cep?._id, keyMessagesOptions);

    if (tacticStateValue.tactic) {
      const { data: tacticData, error } = await updateTactic(
        tacticStateValue.tactic._id,
        formattedTactic,
        tacticStateValue.cepId,
        sendToVeeva,
      );

      const { data: customerEngagementPlanning } = await queryGetCepById({ variables: { id: cepStateValue.cep?._id } });
      if (cepState.cep) {
        cepState.cep.globalCepSyncStatus = (customerEngagementPlanning as CustomerEngagementPlanning).globalCepSyncStatus;
      }

      setIsSubmitting(false);
      if (tacticData && !error) {
        succesToast(t('maya_tactic_updated', { title: tacticData.title }));
      } else {
        warningToast(t('errors_standard'));
      }

      tacticState.tactic = null;
      tacticState.mode = TacticMode.list;
      cepState.tactics = [];
    }
  };

  const buildAssetRequestUrl = (channel: Channel): void => {
    if (!RequestFormUrlMapping[channel]) return;

    const assetRequestUrl = RequestFormUrlMapping[channel];
    const searchParams = new URLSearchParams();
    const formValues = getValues();

    const country = cepState.cep?.customerInitiative?.country
      ? InitiativeCountry[cepState.cep?.customerInitiative?.country as keyof typeof InitiativeCountry]
      : '';
    const therapeuticArea = cepState.cep?.therapeuticArea
      ? TherapeuticAreaRequestFormMapping[cepState.cep?.therapeuticArea as keyof typeof TherapeuticAreaRequestFormMapping]
      : '';

    searchParams.append('assetName', formValues[tacticFormConfig.assetName.name] || '');
    searchParams.append('description', formValues[tacticFormConfig.description.name] || '');
    searchParams.append('country', country);
    searchParams.append('therapeuticArea', therapeuticArea);
    searchParams.append('cepId', cepState.cep?.formattedCepId || '');
    searchParams.append('cepName', cepState.cep?.title || '');
    searchParams.append('tacticId', tacticStateValue.tactic?.formattedTacticId || '');

    window.open(`${assetRequestUrl}${searchParams.toString()}`, '_blank');
  };

  const channelKey = useWatchField(tacticFormConfig.channel.name)?.value;

  const requestAssetButtonConfig = !channelKey || !RequestFormUrlMapping[channelKey as keyof typeof RequestFormUrlMapping] ? null : {
    buttonProps: {
      onClick: () => buildAssetRequestUrl(channelKey as Channel),
      buttonType: ButtonType.primary,
      disabled: !isValid,
    },
    label: t('maya_tactic_form_request_asset_label'),
  };

  useEffect(() => {
    if (channelKey) {
      const formValues = getValues();
      Object.keys(formValues).forEach((element: string) => {
        if (element !== tacticFormConfig.channel.name) {
          setDefaultValue(element, undefined);
          resetField(element);
          setError(element, '');
        }
      });
      setSelectedChannel(channelKey);
      getEngagement({ variables: {
        cepId: cepStateValue.cep?._id,
        channel: channelKey,
      } });
    }
  }, [
    cepStateValue.cep?._id,
    channelKey,
    getEngagement,
    getValues,
    resetField,
    setDefaultValue,
    setError,
    tacticFormConfig.channel.name,
    tacticStateValue.tactic,
  ]);

  const cancelButtonConfig = {
    buttonProps: {
      onClick: cancelTacticForm,
      buttonType: ButtonType.secondary,
    },
    label: t('actions_cancel_label'),
  };

  const submitButtonConfig = {
    buttonProps: {
      onClick: tacticStateValue.tactic?._id ? handleUpdateTacticForm : handleSaveTacticForm,
      buttonType: ButtonType.primary,
      disabled: !isValid || isSubmitting,
    },
    label: t('maya_tactic_form_submit_label'),
    loading: isSubmitting,
  };

  const [nextTactics, setNextTactics] = useState<Tactic[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const { data, loading, count } = useGetNextTacticsQuery(tacticStateValue.tactic?._id || null, queryOptions);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setNextTactics(data);
    }
  }, [data, loading]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return {
    cancelButtonConfig,
    keyMessagesLoading: keyMessagesLoading || medicalStrategiesLoading || internationalMedicalStrategiesLoading || discussionTopicsLoading,
    keyMessagesOptions,
    loading,
    nextTactics,
    onPageChange,
    onPageSizeChange,
    providerFields,
    rowCountState,
    selectedChannel,
    submitButtonConfig,
    t,
    tacticFormConfig,
    channelEngagement,
    channelEngagementLoading,
    allVeevaVaultDocumentsLoading,
    allVeevaVaultDocumentsOptions: allApprovedDocumentIdOptions,
    requestAssetButtonConfig,
  };
};

export default useTacticCreate;
